import { useState } from "react";
const VaultCodeGenerator = () => {
    const NUMBER = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]; //Number of possible digits
    const DIGITS = 4; //Number of digits in the code

    let [knownCode, setKnownCode] = useState('');
    let [codes, setCodes] = useState('');
    let [tryAll, setTryAll] = useState(false);
    let [code, setCode] = useState('');

    function generateAllCodes() {
        let codes = [];
        for (let i = 0; i < Math.pow(NUMBER.length, DIGITS); i++) {
            let code = '';
            let x = i;
            for (let j = 0; j < DIGITS; j++) {
                code = NUMBER[x % NUMBER.length] + code;
                x = Math.floor(x / NUMBER.length);
            }
            codes.push(code);
        }
        return codes;
    }

    function getCode(codes, digit) {
        return codes.filter(code => code.includes(digit.toString()));
    }

    function checkCode(code) {
        let value = code.split('').every(num => knownCode.toString().includes(num));
        return value
    }
    
    function createCode() {
        let allCodes = generateAllCodes();
        let finalCodes = [];
        for (let digit of knownCode.toString()) {
            allCodes = getCode(allCodes, digit);
        }
        for (let code of allCodes) {
            if (checkCode(code)) {
                finalCodes.push(code)
            }
            
        }
        return finalCodes;
    }
    
    function validateCode(value) {
        if (codes) {
            return;
        } 
        if (isNaN(value)) {
            return;
        }
        if (value.length > 4) {
            return setKnownCode(value.slice(0, 4));
        }
        setKnownCode(value);   
    }

    function addDigit(digit) { 
        if (knownCode) {
            if (knownCode.toString().includes(digit.toString())) {
                return;
            }
            digit = knownCode.toString() + digit.toString();
        }
        validateCode(digit);
    }
    
    function deleteDigit() {
        if (knownCode.length > 0) {
            return setKnownCode(knownCode.slice(0, -1));
        }
        deleteAllDigit();
    }

    function deleteAllDigit() {
        setKnownCode('');
        setCodes('');
        setTryAll(false);
    }

    function valide() {
        if (knownCode) {
            setCodes(createCode());
        }
    }

    function nextCode() {
        setCode(codes[1]);
        setCodes(codes.slice(1));
    }

    function end() {
        setTryAll(false);
        setCode('');
        setCodes('');
        setKnownCode('');
    }

    return (
        <>
            <p className="title is-1">{knownCode ? 'Digits: ' + knownCode : "What digit do you have available ? (use the keypad below)"}</p>
            <p className="title is-1">{tryAll ? (code ? code : codes[0]) : ""}</p>
            
            
            <div className="columns is-mobile">
                <div className="column is-1"><a onClick={() => addDigit(7) } className="button is-info is-fullwidth"><i className="fa-solid fa-7"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(8) } className="button is-info is-fullwidth"><i className="fa-solid fa-8"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(9)} className="button is-info is-fullwidth"><i className="fa-solid fa-9"></i></a></div>
                <div className="column is-1"><a onClick={() => deleteDigit()} className="button is-info is-fullwidth"><i className="fa-solid fa-delete-left"></i></a></div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-1"><a onClick={() => addDigit(4) } className="button is-info is-fullwidth"><i className="fa-solid fa-4"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(5) } className="button is-info is-fullwidth"><i className="fa-solid fa-5"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(6) } className="button is-info is-fullwidth"><i className="fa-solid fa-6"></i></a></div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-1"><a onClick={() => addDigit(1) }  className="button is-info is-fullwidth"><i className="fa-solid fa-1"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(2) } className="button is-info is-fullwidth"><i className="fa-solid fa-2"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit(3) } className="button is-info is-fullwidth"><i className="fa-solid fa-3"></i></a></div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-1"><a onClick={() => tryAll ? nextCode() : deleteAllDigit() } className="button is-info is-fullwidth"><i className="fa-solid fa-x"></i></a></div>
                <div className="column is-1"><a onClick={() => addDigit('0') } className="button is-info is-fullwidth"><i className="fa-solid fa-0"></i></a></div>
                <div className="column is-1"><a onClick={() => tryAll ? end() : valide()  } className="button is-info is-fullwidth"><i className="fa-solid fa-check"></i></a></div>
            </div>

            <p className="title is-1">{codes ? 'You have ' + codes.length + ' possibilities' : ""}</p>
            {codes && !tryAll && <button className="button is-info" onClick={() => setTryAll(true)}>Try all</button>}
            
        </>
    );
}

export default VaultCodeGenerator;