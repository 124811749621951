import '../styles/App.css';
import Header from './Header';
import VaultCodeGenerator from './VaultCodeGenerator';
import 'bulma/css/bulma.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useState } from "react";

function App() {
  return (
    <div className="has-background-grey-light" style={{minHeight: "100vh"}}>
      <Header name='Payday3 helper' />
      <VaultCodeGenerator />
    </div>
  );
}

export default App;
