const Header = (props) => {
    return (
        <>
        <section className="hero is-primary">
            <div className="hero-body">
                <h1 className="title has-text-centered">
                {props.name.toUpperCase()}
                </h1>
            </div>
        </section>
            </>
    );
}

export default Header;